import React from "react"
import { graphql, navigate } from "gatsby"
import { Helmet } from "react-helmet"

import Layout from "@layout"
import Grid from "@layout/grid"

import * as styles from "./styles.module.scss"

class ContactPage extends React.Component {
  // data alias
  page = this.props.data.datoCmsContactPageTruckVan
  trackedUtms = [
    "lt_utm_medium",
    "lt_utm_source",
    "lt_utm_campaign",
    "lt_utm_content",
    "lt_utm_keyword",
    "lt_utm_adname"
  ]

  constructor(props) {
    super(props)
    this.state = {
      email: "",
      first_name: "",
      last_name: "",
      message: "",
      phone: "",
      product: this.parseParams(this.props.location.search).product
        ? this.parseParams(this.props.location.search).product
        : ""
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value
    })
  }

  handleSubmit(e) {
    e.preventDefault()

    let gaid = ""
    if (typeof window !== "undefined") {
      gaid = window?.google_tag_manager?.mb?.h?.[1]?.message?.[2]?.gaID || ""
    }

    const utmParams = {
      lt_utm_adname: sessionStorage.getItem("utm_adname") || "",
      lt_utm_campaign: sessionStorage.getItem("utm_campaign") || "",
      lt_utm_content: sessionStorage.getItem("utm_content") || "",
      lt_utm_keyword: sessionStorage.getItem("utm_keyword") || "",
      lt_utm_medium: sessionStorage.getItem("utm_medium") || "",
      lt_utm_source: sessionStorage.getItem("utm_source") || ""
    }

    const dest = "/contact-thank-you"
    const fieldData = { ...this.state, ...utmParams, gaid }

    const options = {
      body: this.encode({ "form-name": "contact", ...fieldData }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      method: "POST"
    }

    fetch("/", options, fieldData)
      .then(function (response) {
        if (window && window.Rollbar) {
          window.Rollbar.info("Contact form submitted", fieldData)
        }
        navigate(dest)
      })
      .catch(function (error) {
        if (window && window.Rollbar) {
          window.Rollbar.error("Contact form failed to submit", fieldData)
        }
        console.log(error)
      })
  }

  encode(data) {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  parseParams(querystring) {
    // parse query string
    const params = new URLSearchParams(querystring)

    const obj = {}

    // iterate over all keys
    for (const key of params.keys()) {
      if (params.getAll(key).length > 1) {
        obj[key] = params.getAll(key)
      } else {
        obj[key] = params.get(key)
      }
    }

    return obj
  }

  render() {
    const bodyContent =
      this.page.bodyNode && this.page.bodyNode.childMarkdownRemark
        ? this.page.bodyNode.childMarkdownRemark.html
        : this.page.body

    return (
      <>
        <Helmet>
          <html lang="en" />
        </Helmet>

        <Layout
          seoMetaTags={this.page.seoMetaTags}
          useTriangleBackground={true}
          menu={this.props.data.menu}
          footer={this.props.data.footer}
        >
          <div className={styles.contactPage}>
            <h1>{this.page.title}</h1>
            <div
              className={styles.body}
              dangerouslySetInnerHTML={{
                __html: bodyContent
              }}
            />
            <div className={styles.gridContent}>
              <Grid items="2" hasChildren gutter="15" layout="2">
                <div>
                  <form name="contact" data-netlify="true" onSubmit={this.handleSubmit}>
                    <h2>Send us an email</h2>

                    <p>
                      <label>
                        First name{" "}
                        <input
                          type="text"
                          name="first_name"
                          required
                          value={this.state.first_name}
                          onChange={this.handleInputChange}
                        />
                      </label>
                    </p>
                    <p>
                      <label>
                        Last name{" "}
                        <input
                          type="text"
                          name="last_name"
                          required
                          value={this.state.last_name}
                          onChange={this.handleInputChange}
                        />
                      </label>
                    </p>
                    <p>
                      <label>
                        Email address{" "}
                        <input
                          type="email"
                          name="email"
                          required
                          value={this.state.email}
                          onChange={this.handleInputChange}
                        />
                      </label>
                    </p>
                    <p>
                      <label>
                        Phone{" "}
                        <input
                          type="tel"
                          name="phone"
                          value={this.state.phone}
                          onChange={this.handleInputChange}
                        />
                      </label>
                    </p>
                    <p>
                      <label>
                        Product Name{" "}
                        <input
                          type="text"
                          name="product"
                          value={this.state.product}
                          onChange={this.handleInputChange}
                        />
                      </label>
                    </p>
                    <p>
                      <label>
                        Message{" "}
                        <textarea
                          name="message"
                          value={this.state.message}
                          onChange={this.handleInputChange}
                        ></textarea>
                      </label>
                    </p>
                    <p>
                      <button type="submit">Send Email</button>
                    </p>

                    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                    <input type="hidden" name="form-name" value="contact" />
                    <input type="hidden" name="gaid" />
                    {this.trackedUtms.map((key) => {
                      return <input type="hidden" key={key} name={key} />
                    })}
                  </form>
                </div>
                <div>
                  <div className={styles.iframe_wrapper}>
                    <iframe
                      title="Google Maps"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3089.4723800894058!2d-84.44186074912201!3d39.25484733329949!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88404df5c2bfffff%3A0x7718db175b6bfa65!2s10381%20Evendale%20Dr%20%23100%2C%20Cincinnati%2C%20OH%2045241!5e0!3m2!1sen!2sus!4v1634741320661!5m2!1sen!2sus"
                      width="600"
                      height="450"
                      style={{ border: "0" }}
                      allowFullScreen=""
                      loading="lazy"
                    ></iframe>
                  </div>
                  <h3>Mike Albert Truck &amp; Van Equipment</h3>
                  {this.page.address && (
                    <a
                      target="_blank"
                      href={`https://maps.google.com/?q=${this.page.address}`}
                      rel="noreferrer"
                    >
                      {this.page.address}
                    </a>
                  )}
                  {this.page.phoneNumber && (
                    <a href={`tel:${this.page.phoneNumber.replace(/-/g, "")}`}>
                      {this.page.phoneNumber}
                    </a>
                  )}
                  <h4>Hours</h4>
                  <div className={styles.hours}>
                    {this.page.hours.map((hour, index) => {
                      return (
                        <div key={index}>
                          <p>{hour.daysOfWeek}</p>
                          <p>{hour.times}</p>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </Grid>
            </div>
          </div>
        </Layout>
      </>
    )
  }
}

export default ContactPage

export const query = graphql`
  query ContactPageTruckVanQuery {
    ...MegaMenu
    ...Footer

    datoCmsContactPageTruckVan {
      permalink
      title
      body
      seoMetaTags {
        tags
      }
      bodyNode {
        childMarkdownRemark {
          html
        }
      }
      address
      phoneNumber
      hours {
        times
        daysOfWeek
      }
    }
  }
`
